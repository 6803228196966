<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Calcular Frete</h4>
                            <p class="card-description">
                                Insira as informações básicas
                            </p>
                            <div>
                                <form class="forms-sample row">
                                    <div class="form-group col-md-4">
                                        <label for="tipo">Veiculo</label
                                        ><select
                                            aria-label="Default select example"
                                            class="form-select"
                                            v-model="frete.tipo"
                                            @change="tipoFrete"
                                        >
                                            <option value="moto">Moto</option>
                                            <option value="carro">Carro</option>
                                        </select>
                                        <div
                                            v-if="erros.tipo"
                                            class="invalid-feedback"
                                        >
                                            {{ erros.tipo }}
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="peso">Peso (kg)</label
                                        ><input
                                            type="text"
                                            id="peso"
                                            class="form-control"
                                            v-model="frete.peso"
                                        />
                                        <div
                                            v-if="erros.peso"
                                            class="invalid-feedback"
                                        >
                                            {{ erros.peso }}
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="valor">Valor</label>
                                        <money
                                            class="form-control"
                                            v-model="frete.valor"
                                            v-bind="money"
                                        ></money>
                                        <div
                                            v-if="erros.valor"
                                            class="invalid-feedback"
                                        >
                                            {{ erros.valor }}
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="cep_origem"
                                            >CEP de origem</label
                                        ><input
                                            type="text"
                                            id="cep_origem"
                                            class="form-control"
                                            v-model="frete.cepOrigem"
                                            v-maska="'#####-###'"
                                        />
                                        <div
                                            v-if="erros.cepOrigem"
                                            class="invalid-feedback"
                                        >
                                            {{ erros.cepOrigem }}
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="cep_destino"
                                            >CEP de destino</label
                                        ><input
                                            type="text"
                                            id="cep_destino"
                                            class="form-control"
                                            v-model="frete.cepDestino"
                                            v-maska="'#####-###'"
                                        />
                                        <div
                                            v-if="erros.cepDestino"
                                            class="invalid-feedback"
                                        >
                                            {{ erros.cepDestino }}
                                        </div>
                                    </div>
                                    <div
                                        v-if="erro"
                                        class="alert alert-danger mt-2"
                                        role="alert"
                                    >
                                        {{ msgErro }}
                                    </div>
                                    <div class="col-12">
                                        <button
                                            type="button"
                                            class="
                                                btn btn-success
                                                me-2
                                                float-end
                                                mb-4
                                            "
                                            @click.prevent="calcularFrete()"
                                        >
                                            Calcular
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 grid-margin stretch-card" v-if="resultado">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-12">
                                <h4 class="card-title">Frete</h4>
                                <div v-if="resultado.regiao_valida">
                                    <h5>
                                        <strong>Valor do frete: </strong>
                                        {{ formatarValor(resultado.valor) }}
                                    </h5>
                                    <h5 class="py-2">
                                        <strong>Valor total: </strong>
                                        {{
                                            formatarValor(
                                                resultado.valor + frete.valor
                                            )
                                        }}
                                    </h5>
                                    <h5>
                                        <strong>Endereço de destino: </strong>
                                        {{ resultado.endereco.destino }}
                                    </h5>
                                </div>
                                <div v-else>
                                    <h5>
                                        <strong
                                            >Não é possível efetuar entrega no
                                            endereço informado</strong
                                        >
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosApi from "@/axios";
import { Money } from "v-money";
import store from "@/store";

export default {
    name: "Frete",
    components: { Money },
    data() {
        return {
            erro: false,
            mgErro: "",
            frete: {
                tipo: "",
                peso: "",
                valor: "",
                cepOrigem: "02273-010",
                cepDestino: "",
            },
            resultado: null,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                precision: 2,
                masked: false,
            },
            erros: {
                tipo: "",
                peso: "",
                valor: "",
                cepOrigem: "",
                cepDestino: "",
            },
        };
    },
    methods: {
        async calcularFrete() {
            this.resultado = null
            this.limparCampos()
            if (this.validarCampos()) {
                try {
                    this.erro = false;
                    this.msgErro = "";
                    store.commit("setLoading", true);
                    let data = {
                        tipo: this.frete.tipo,
                        peso: this.frete.peso,
                        valor: this.frete.valor,
                        cep_origem: this.frete.cepOrigem,
                        cep_destino: this.frete.cepDestino,
                    };
                    const response = await axiosApi.post(
                        "/pedidos/calcular-frete",
                        data
                    );
                    this.resultado = response.data;
                } catch (error) {
                    this.erro = true;
                    this.msgErro = error.response.data.erro;
                } finally {
                    store.commit("setLoading", false);
                }
            }
        },

        formatarValor(valor) {
            var valorFormatado = valor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
            return valorFormatado;
        },
        tipoFrete() {
            if (this.frete.tipo == "moto") {
                this.frete.peso = "1";
            } else if (this.frete.tipo == "carro") {
                this.frete.peso = "30";
            } else {
                this.frete.peso = "";
            }
        },
        validarCampos() {
            let totalErros = 0;
            if (this.frete.tipo == "") {
                this.erros.tipo = "Preencha o campo tipo de veiculo.";
                totalErros++;
            }
            if (this.frete.peso == "") {
                this.erros.peso = "Preencha o campo peso.";
                totalErros++;
            }
            if (this.frete.valor == "") {
                this.erros.valor = "Preencha o campo valor.";
                totalErros++;
            }
            if (this.frete.cepOrigem == "") {
                this.erros.cepOrigem = "Preencha o campo cep de origem.";
                totalErros++;
            }
            if (this.frete.cepDestino == "") {
                this.erros.cepDestino = "Preencha o campo cep de destino.";
                totalErros++;
            }

            if (this.frete.tipo == "moto"){
                if (this.frete.peso < 1){
                    this.erros.peso = "O peso não pode ser menor que 1kg."
                }

                if (this.frete.peso > 20){
                    this.erros.peso = "O peso não pode ser maior que 20kg."
                }

            }

            if (this.frete.tipo == "carro"){
                if (this.frete.peso < 30){
                    this.erros.peso = "O peso não pode ser menor que 30kg."
                }

                if (this.frete.peso > 200){
                    this.erros.peso = "O peso não pode ser maior que 200kg."
                }

            }

            return totalErros == 0;
        },
        limparCampos() {
            this.erros = {
                tipo: "",
                peso: "",
                valor: "",
                cepOrigem: "",
                cepDestino: "",
            };
        },
    },
};
</script>

<style>
.form-select {
    height: 33px;
    padding: 0px 10px;
}
.invalid-feedback {
    display: block;
    font-size: 12px;
}
</style>